// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-layouts-article-js": () => import("./../../../src/layouts/article.js" /* webpackChunkName: "component---src-layouts-article-js" */),
  "component---src-layouts-blog-category-js": () => import("./../../../src/layouts/blogCategory.js" /* webpackChunkName: "component---src-layouts-blog-category-js" */),
  "component---src-layouts-blog-label-js": () => import("./../../../src/layouts/blogLabel.js" /* webpackChunkName: "component---src-layouts-blog-label-js" */),
  "component---src-layouts-category-js": () => import("./../../../src/layouts/category.js" /* webpackChunkName: "component---src-layouts-category-js" */),
  "component---src-layouts-page-js": () => import("./../../../src/layouts/page.js" /* webpackChunkName: "component---src-layouts-page-js" */),
  "component---src-layouts-product-js": () => import("./../../../src/layouts/product.js" /* webpackChunkName: "component---src-layouts-product-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-confirm-page-js": () => import("./../../../src/pages/confirm-page.js" /* webpackChunkName: "component---src-pages-confirm-page-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latest-articles-js": () => import("./../../../src/pages/latest-articles.js" /* webpackChunkName: "component---src-pages-latest-articles-js" */),
  "component---src-pages-selections-for-sale-js": () => import("./../../../src/pages/selections-for-sale.js" /* webpackChunkName: "component---src-pages-selections-for-sale-js" */)
}

